<template>
  <div>
    <!-- popup 필수  -->
    <ejs-dialog
        ref="dialog"
        :header="title"
        width="400"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :close="onClickClose"
    >
      <div class="content-wrapper">
        <div class = "content-body">
          <article class="body-article">
            <div class="article-left" style="width: 100%">
              <section class="article-section">
                <div class="section-body">
                  <div class="body-box control_wrapper">
                    <ejs-treeview
                        ref="treeView"
                        id="treeView"
                        :fields="treeField.fields"
                        :showCheckBox='false'
                        :nodeSelected = "onNodeSelected"
                        :loadOnDemand="false"
                    />
                  </div>
                </div>
              </section>
            </div>
          </article>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="create keyColor">
              <erp-button button-div="SAVE" :is-shortcut-button="true" @click.native="onClickSubmit">확인</erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="onClickClose">닫기</erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name :'stockTreePopup',
  mixins : [commonMixin, messagePopupDialogMixin],
  components: {
    ErpButton,
  },
  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return{
      title:"",
      popupSearch:{
        productName: null,
      },
      treeField: {
        fields:{},
      },
    };
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  computed:{
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {

    //####################### Button function ###############################
    onClickSubmit(){
      if(!this.treeField.selectNode){
        return;
      }
      this.$emit('popupConfirm', this.treeField.selectNode);
      // this.hide();
    },
    onClickClose(){
      // this.hide();
      this.$emit('popupClosed');
    },
    //####################### 부모화면에서 호출하는 function ###############################
    initShow(title,treeField) {
      this.title = title;
      this.treeField = treeField;
    },
    //Tree 관련
    onNodeSelected(args){
      this.treeField.selectNode = args.nodeData;
      //선택된 행이 최상위 행이면 텍스트 X
      if(this.treeField.selectNode.id === "0") {
        this.treeField.selectNode.text = "";
      }
    },
  },
};
</script>

<style scoped>
.control_wrapper {
  display: block;
  min-width: 250px;
  max-width: 350px;
  min-height: 350px;
  max-height: 350px;
  margin: auto;
  overflow: auto;
  border: 1px solid #dddddd;
  border-radius: 3px;
}
/*트리 왼쪽 정렬*/
body .e-popup.e-lib::v-deep .e-list-parent .e-list-item {
  text-align: left;
}
/*트리 레벨별 들여쓰기*/
body .e-popup.e-lib::v-deep .e-list-parent {
  padding: 0 0 0 24px;
}
/*트리 1레벨별 들여쓰기*/
body .e-popup.e-lib::v-deep .e-treeview > .e-list-parent {
  padding: 0 0 0 6px;
}
/*트리 내부행 스크롤 삭제*/
body .e-treeview::v-deep .e-ul { overflow-y:hidden ;}
</style>