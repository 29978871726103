import GolfErpAPI from "@/api/v2/GolfErpAPI";
export const ROOT_PATH = '/stock-supplies-std';

class StockSuppliesStd {
    /** 저장품 분류 조회 */
    async getSuppliesClassList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/classList` ,{params} );
        return data;
    }
    /** 저장품 분류 저장 */
    async postSuppliesClassDetail(params){
        const {data} = await  GolfErpAPI.http.post(`${ROOT_PATH}/classDetail` ,params);
        return data;
    }
    /** 저장품 분류 수정 */
    async patchSuppliesClassDetail(params){
        const {data} = await  GolfErpAPI.http.patch(`${ROOT_PATH}/classDetail` ,params);
        return data;
    }
    /** 저장품 리스트 조회 */
    async getSuppliesCodeList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/list` ,{params} );
        return data;
    }
    /** 저장품 상세 조회 */
    async getSuppliesCodeDetail(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/detail` ,{params} );
        return data;
    }
    /** 저장품 상세 저장 */
    async postSuppliesCodeDetail(params){
        const {data} = await  GolfErpAPI.http.post(`${ROOT_PATH}/detail` ,params);
        return data;
    }
    /** 저장품 상세 수정 */
    async patchSuppliesCodeDetail(params){
        const {data} = await  GolfErpAPI.http.patch(`${ROOT_PATH}/detail` ,params);
        return data;
    }
}
export default new StockSuppliesStd();
